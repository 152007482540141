export const environment = {
  production: false,
  environmentName: 'test',
  api: 'https://solvay-barometer-test.appspot.com',
  firebase: {
    apiKey: 'AIzaSyCcc_BiwcIXArcQu-GyR4iYAz-BooZ88B8',
    authDomain: 'solvay-barometer-test.firebaseapp.com',
    databaseURL: 'https://solvay-barometer-test.firebaseio.com',
    projectId: 'solvay-barometer-test',
    storageBucket: 'solvay-barometer-test.appspot.com',
    messagingSenderId: '127992349043',
    appId: '1:127992349043:web:d21ebafcee80a590804506',
  },
  gtagTrackingId: 'UA-115813374-10',
};
